/**
 * Part of Genesis application
 *
 * @copyright 2019 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultLocaleMessages from '../lang/es'
import 'vue2-datepicker/locale/es'

const defaultLocale = 'es-MX'
const defaultFallbackLocale = 'es'

const currentLocale = document.querySelector('html').getAttribute('lang')
const messages = {
    [defaultFallbackLocale]: defaultLocaleMessages,
    [defaultLocale]: defaultLocaleMessages,
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultFallbackLocale,
    messages,
})

const loadedLocales = [defaultLocale, defaultFallbackLocale]

function setI18nLocale(locale) {
    i18n.locale = locale
    document.querySelector('html').setAttribute('lang', locale)
    return locale
}

async function tryLoadVue2DatePickerLocale(locale) {
    try {
        await import(`vue2-datepicker/locale/${locale}`)
    } catch (error) {
        console.error('Failed to load vu2-datepicker locale %s. Error: %o', locale, error)
        return false
    }
    return true
}

async function tryLoadLocale(locale) {
    if (loadedLocales.indexOf(locale) > -1) {
        return true
    }
    try {
        const module = await import(`../lang/${locale}`)
        i18n.setLocaleMessage(locale, module.default)
        loadedLocales.push(locale)
    } catch (error) {
        return false
    }
    return true
}
/**
 * @param {string} locale The new locale string.
 * @returns {Promise<*|string|string>}
 */
export async function loadLocaleAsync(locale) {
    if (locale === null || locale === undefined || locale === '' || typeof locale !== 'string' || locale === i18n.locale) {
        return i18n.locale
    }

    const lang = locale.split('-')[0]
    if (i18n.locale !== locale) {
        if (! (await tryLoadVue2DatePickerLocale(locale) || await tryLoadVue2DatePickerLocale(lang))) {
            console.error('Failed to load vue2-datepicker locale %s.', locale)
        }
    }

    if (! (await tryLoadLocale(locale) || await tryLoadLocale(lang))) {
        console.error('Failed to load locale %s.', locale)
        return i18n.locale
    }
    return setI18nLocale(locale)
}

loadLocaleAsync(currentLocale).then(r => console.info('Loaded locale %s', r))
